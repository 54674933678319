:root {
  --main-bg-color: #F4F4FF;
  --second-bg-color: #071F5A;
  --highlight-color: #4972e6;
  --color-white: #FFFFFF;
  --text-color-light-bg: #425466;
  --text-color-dark-bg: #adbdcc;
  --rotate-speed: 40;
  --count: 8;
  --easeInOutSine: cubic-bezier(0.37, 0, 0.63, 1);
  --easing: cubic-bezier(0.000, 0.37, 1.000, 0.63);
}

*, span > code > span {
  scroll-behavior: smooth;
  font-family: 'Spline Sans Mono', monospace, 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  line-height: 1.46;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  font-size: calc(14px + 0.390625vw);

}

html {
  scroll-behavior: smooth !important;
  cursor: url('../public/assets/cursor_darkblue.svg'), auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark-bg {
  background-color: #071f5a;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f4f4ff' fill-opacity='0.07'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");  color: var(--text-color-dark-bg);
  box-shadow: 0 0 80px 80px #071F5A inset;

}

.dark-bg:hover,
.sc-gsTDqH:hover, .section-footer {
  cursor: url('../public/assets/cursor_white.svg'), auto !important;
}

.light-bg {
  color: var(--text-color-light-bg);
  background-image: linear-gradient(to right, #ffffff, #bed0ff, #6790f8, #bed0ff) !important;
  box-shadow: 0 4px 15px 0 #0f3cae;
}

/*scrollbar*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}